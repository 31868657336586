@tailwind base;
@tailwind components;
@tailwind utilities;

.canvasScreenShot {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}
.canvasDisplay{
    opacity: 100;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
}